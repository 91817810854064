import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Events from '@activebrands/core-web/libs/Events';
import { AccountEvents } from '@activebrands/core-web/libs/Events/types';

const FormLoader = () => {
    const handleSubmit = () => {
        Events.trigger(AccountEvents.EMAIL_SIGNUP, {});
    };

    useEffect(() => {
        document.addEventListener('d365mkt-afterformsubmit', handleSubmit);

        return () => {
            document.removeEventListener('d365mkt-afterformsubmit', handleSubmit);
        };
    }, []);

    return (
        <Helmet>
            <script src="https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/global/FormLoader/FormLoader.bundle.js" />
        </Helmet>
    );
};

export default FormLoader;
